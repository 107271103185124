

.spContainer {
  width: 70%; /* Set the width to 70% of the parent container */
  max-width: 1200px; /* Adjust based on your design, keeps it responsive */
  margin: 0 auto; /* This centers the container */
  padding: 60px; /* Adds some padding inside the container */
  background-color: #fff; /* Example background color */
  /* Add other styles such as border, box-shadow as per your design */
  display: flex;
  /* Using flexbox to align items */
  justify-content: space-between;
  /* Space out the logo, search bar, and other items */
  align-items: center;
  /* Center items vertically */
  /* position: relative; */ 
  /* Default positioning */
  z-index: 10;
  /* Ensure it's above other content */
  flex-direction: column;
  /* box-sizing: border-box; */
}

.auContainer > :first-child {
  margin-top: 10%; /* Adjust the value as needed */

}
.auContainer > :last-child {
  margin-bottom: 10%; /* Adjust the value as needed */
}

.spNavRow1 {
  display: flex;
  align-items: center;
  justify-content: start; /* Align items to the start of the container */
  width: 100%;
  padding: 10px; /* Add some padding around the elements */
  box-sizing: border-box; /* Ensure padding doesn't affect the overall width */
}

.spCategory,
.spType {
  margin-right: 10px; /* Space between elements */
  width: 18%; /* Set a percent  width for select elements(150px before) */
  height: 38px; /* Slightly taller for a balanced look */
  border: 1px solid #cccccc; /* Subtle border for definition */
  border-radius: 4px; /* Rounded corners for a modern look */
  box-sizing: border-box; /* Include padding and border in the height */
  background-color: #ffffff; /* White background */
  color: #333333; /* Slightly darker text for better readability */
  font-size: 14px; /* Set a standard font size */
}

.spInputContainer {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align the input and button to the start of the container */
}

.spInputWords {
  border: 1px solid #cccccc;
  border-right: none; /* Remove the right border where the button will be */
  width: 100%; /* percent  width for the input(before 600px) */
  height: 38px; /* Match the height of the selects */
  margin-right: -1px; /* Align the button with the input */
  border-radius: 4px 0 0 4px; /* Rounded corners on the left side only */
  padding-left: 8px; /* Some padding inside the input box */
  box-sizing: border-box; /* Include padding and border in the height */
  font-size: 14px; /* Match font size with select elements */
}

.spInputButton {
  border: 1px solid #cccccc;
  background-color: #007bff; /* A pleasant shade of blue */
  color: #ffffff; /* White text for the button */
  height: 40px; /* Height to match the input field */
  padding: 0 15px; /* Padding on the sides of the button text */
  border-radius: 0 4px 4px 0; /* Rounded corners on the right side */
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  box-shadow: 0 2px 4px rgba(0, 123, 255, 0.2); /* Slight shadow for depth */
  font-size: 14px; /* Match font size with other elements */
  box-sizing: border-box; /* Include padding and border in the height */
}

/* Hover effects for button */
.spInputButton:hover {
  background-color: #0056b3; /* Darker shade when hovered */
  box-shadow: 0 2px 4px rgba(0, 123, 255, 0.4); /* More pronounced shadow on hover */
}



.spNavRow2 {
  position: relative; /* Add this to ensure the dropdown is positioned relative to this container */
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align button to the end of the container */
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc; /* Add a bottom border */
}

.spSortButton {
  border: 1px solid #cccccc;
  background-color: #f8f8f8;
  color: #333333;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.spSortText {
  margin-right: 5px; /* Add some space before the arrow */
  margin-left: 5px;  /* Add some space after the sort by */
}

.spSortArrow {
  display: inline-block;
  transform: rotate(180deg); /* Flip the arrow to point upwards */
}

/* Make sure the sort menu appears below the button */
.spSortMenu {
  position: absolute;
  right: 0; /* Align the menu to the right side of the .spNavRow2 */
  top: 100%; /* Position the menu just below the button */
  background-color: white; /* Add a background color so it stands out over other content */
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Optional: add a shadow for better visibility */
  width: auto; /* Set the width of the menu, can be fixed or auto */
  z-index: 1000; /* Ensure the menu appears above other content */
}

/* When the sort menu is visible, you may want to change the arrow direction */
.spSortMenu.visible .spSortArrow {
  transform: rotate(0deg); /* Arrow points down when the menu is visible */
}

/* Styling for the sort menu items */
.spSortMenu div {
  padding: 5px 10px;
  cursor: pointer;
}

/* Styling when sort menu is visible */
.spSortMenu.visible {
  display: block;
}

/* Hidden by default */
.spSortMenu {
  display: none;
}
.spNavRow3 {
  position: relative; /* Add this to ensure the dropdown is positioned relative to this container */
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align button to the end of the container */
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc; /* Add a bottom border */
  flex-wrap: wrap; /* Allow items to wrap onto the next line */
}
.spNavCategoryLink {
  text-decoration: none; /* Remove the underline from links */
  color: #000; /* Set the color of the links, change as needed */
  margin-right: 20px; /* Add space between the links, adjust as needed */
  margin-bottom: 20px; /* Add bottom margin to create space between rows */
  /* Add other styles like padding, font-weight, etc., according to your design */
}
.spNavCategoryLink:last-child {
  margin-right: 0;
}

.spTwoColGrid {
  display: flex;
  align-items: flex-start; /* Align to the top */
  justify-content: flex-start; /* Align items to the start of the container */
  width: 100%;
  padding: 10px; /* Add some padding around the elements */
  box-sizing: border-box; /* Ensure padding doesn't affect the overall width */
}

.spLeftNav1 {
  /* display: flex; */
  /* Give the sidebar a flexible size but allow it to shrink/grow as needed */
  flex: 1; 
  padding: 10px;
  box-sizing: border-box; /* Include padding in the width calculation */
    /* Add a light gray border to the right side */
  border-right: 1px solid #ccc;
}

.spLeftTypes {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.spLeftTypeslist {
  padding: 0; /* Remove padding from list items */
  margin: 0; /* Remove margin to start from the very top */
}

.spLeftTypeslist:not(:first-child) {
  padding-left: 20px; /* Add an indent to all list items except the first */
}

.spLeftTypeslink {
  text-decoration: none; /* Remove the default text decoration */
  color: #000; /* Set the text color */
  display: block; /* Make the links fill the available horizontal space */
  padding: 5px; /* Add some padding for better clickability and appearance */
  transition: background-color 0.3s; /* Smooth transition for hover effect */

  display: inline-block; /* Ensure the element respects width */
  white-space: normal; /* Allow the text to wrap to the next line */
  /* word-wrap: break-word;  */
  /* Break long words if necessary */
  max-width: 150px; /* Set your desired maximum width */
  overflow-wrap: break-word; /* Break the word only if neces
}

.spLeftTypeslink:hover {
  background-color: #f0f0f0; /* Change background on hover */
}

.spResultsGrid {
  display: flex;
  flex-direction: column; /* Stack .spStoreGrid items vertically */
  align-items: flex-start; /* Align items to the start of the container */
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.spStoreGrid {
  display: flex;
  flex-direction: row; /* Align image and info side by side */
  align-items: flex-start; /* Align items to the top */
  width: 100%;
  margin-bottom: 20px; /* Space between store grid items */
  box-sizing: border-box;
}

.spStoreImage {
  flex: 0 0 auto; /* Do not grow or shrink, use image's size */
  margin-right: 20px; /* Space between image and info */
}

.spStoreImage img {
  width: 300px; /* Set a fixed width for the image */
  height: auto; /* Maintain aspect ratio */
}

.spStoreInfo {
  flex: 1; /* Take up the remaining space */
  display: flex;
  flex-direction: column; /* Stack info items vertically */
  padding-left: 20px; /* Add some padding for spacing from the image */
}

.spStoreInfo * { /* This will target all elements within .spStoreInfo */
  font-size: 16px; /* Increase the base font size */
  margin: 5px 0; /* Add some margin to each element */
}

.spStoreName  {
  /* margin: 0;
  padding: 0; */
  font-size: 24px; /* Larger text for store name */
  font-weight: bold; /* Make the store name bold */
  color: #333; /* Darker color for the name */
}

.spPrimaryTypeDisplayName,
.spStoreAddress,
.spStoreTypes,
.spStorePhone {
  /* margin: 5px 0;  */
  /* Vertical spacing between info items */
  /* padding: 0; */
  font-size: 18px; /* Standard text size */
  color: #555; /* Slightly lighter color for details */
}

.spStoreRating {
  display: flex;
  align-items: center; /* Align rating number and text */
  font-size: 18px;
  /* margin: 5px 0;  */
  /* Space between rating and other info */
}

.spStoreRating span:first-child {
  font-weight: bold; /* Make the rating number bold */
  margin-right: 5px; /* Space between rating number and 'reviews' */
}

.spMapUrl,
.spStoreWebsite {
  color: #007bff; /* Use a blue color for links */
  text-decoration: none; /* No underline */
  margin: 5px 0; /* Space between link and other info */
  font-size: 18px;
}

.spMapUrl:hover,
.spStoreWebsite:hover {
  text-decoration: underline; /* Underline on hover for better visibility */
}

.sphighlight {
  background-color: #d4edda; /* Soft green background */
  color: #155724; /* Dark green text color for contrast */
  background-color: #fff3cd; /* Light yellow background */
  color: #856404; /* Dark yellow text color for contrast */
  background-color: #f8d7da; /* Soft pink background */
  color: #721c24; /* Dark pink/red text color for contrast */
  border-radius: 5px; /* Optional: to give a rounded edge */
  padding: 2px 4px; /* Optional: to add some padding */
}
.spStoreRating .fas {
  color: gold; /* Change color to gold for stars */
}

/* Ensure that the layout does not break on smaller screens */
@media (max-width: 768px) {
  .spStoreGrid {
    flex-direction: column; /* Stack image and info on top of each other */
  }

  .spStoreImage {
    margin-bottom: 10px; /* Space between image and info on small screens */
  }
}
