.spscontainer {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .spscategory {
    margin-bottom: 20px;
  }
  
  .spscategory h2 {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
  }
  
  .spscategory ul {
    list-style-type: none;
    padding: 0;
  }
  
  .spscategory ul ul {
    margin-left: 20px;
  }
  
  .spscategory ul li {
    margin: 5px 0;
  }
  
  .spscategory a {
    text-decoration: none;
    color: #007BFF;
  }
  
  .spscategory a:hover {
    text-decoration: underline;
  }
  
  .spscategory img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  
  .spscontact-info {
    margin-bottom: 20px;
  }
  
  .spscontact-info h4 {
    margin-top: 10px;
  }
  
  .spscontact-info ul {
    padding-left: 20px;
  }
  
  .spscategory h4 {
    margin-bottom: 5px;
  }
  
  .spscategory h4 {
    font-size: 1.1em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .spscategory li a {
    font-size: 1em;
    color: #007BFF;
  }
  
  .spscategory li a:hover {
    text-decoration: underline;
  }
  
  .spscategory img {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    max-width: 100%;
  }
  
  h2, h3, h4 {
    color: #333;
  }
  