



.ppPrivacyContainer {
  width: 70%; /* Set the width to 70% of the parent container */
  max-width: 1200px; /* Adjust based on your design, keeps it responsive */
  margin: 0 auto; /* This centers the container */
  padding: 20px; /* Adds some padding inside the container */
  background-color: #fff; /* Example background color */
  /* Add other styles such as border, box-shadow as per your design */
}

.ppPrivacyContainer > :first-child {
  margin-top: 10%; /* Adjust the value as needed */
}

/* Ensure you have styles for h1, p, ul, li to match the privacy policy's visual design */
/* h1, p, ul, li {

  color: #333; 

} */