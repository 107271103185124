

.fpContainer {
  width: 70%; /* Set the width to 70% of the parent container */
  max-width: 1500px; /* Adjust based on your design, keeps it responsive */
  margin: 0 auto; /* This centers the container */
  padding: 60px; /* Adds some padding inside the container */
  background-color: #fff; /* Example background color */
  /* Add other styles such as border, box-shadow as per your design */
  display: flex;
  /* Using flexbox to align items */
  justify-content: space-between;
  /* Space out the logo, search bar, and other items */
  align-items: center;
  /* Center items vertically */
  /* position: relative; */ 
  /* Default positioning */
  z-index: 10;
  /* Ensure it's above other content */
  flex-direction: column;
  /* box-sizing: border-box; */
}


.fpNavRow1 {
  display: flex;
  align-items: center;
  justify-content: start; /* Align items to the start of the container */
  width: 100%;
  padding: 10px; /* Add some padding around the elements */
  box-sizing: border-box; /* Ensure padding doesn't affect the overall width */
}


.fpPriceFilter,
.fpCategory,
.fpType {
  margin-right: 10px; /* Space between elements */
  width: 18%; /* Set a percent  width for select elements(150px before) */
  height: 38px; /* Slightly taller for a balanced look */
  border: 1px solid #cccccc; /* Subtle border for definition */
  border-radius: 4px; /* Rounded corners for a modern look */
  box-sizing: border-box; /* Include padding and border in the height */
  background-color: #ffffff; /* White background */
  color: #333333; /* Slightly darker text for better readability */
  font-size: 14px; /* Set a standard font size */
}
.fpPriceFilter{
  display: none;
}

.fpInputContainer {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align the input and button to the start of the container */
}

.fpInputWords {
  border: 1px solid #cccccc;
  border-right: none; /* Remove the right border where the button will be */
  width: 100%; /* percent  width for the input(before 600px) */
  height: 38px; /* Match the height of the selects */
  margin-right: -1px; /* Align the button with the input */
  border-radius: 4px 0 0 4px; /* Rounded corners on the left side only */
  padding-left: 8px; /* Some padding inside the input box */
  box-sizing: border-box; /* Include padding and border in the height */
  font-size: 14px; /* Match font size with select elements */
}

.fpInputButton {
  border: 1px solid #cccccc;
  background-color: #007bff; /* A pleasant shade of blue */
  color: #ffffff; /* White text for the button */
  height: 40px; /* Height to match the input field */
  padding: 0 15px; /* Padding on the sides of the button text */
  border-radius: 0 4px 4px 0; /* Rounded corners on the right side */
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  box-shadow: 0 2px 4px rgba(0, 123, 255, 0.2); /* Slight shadow for depth */
  font-size: 14px; /* Match font size with other elements */
  box-sizing: border-box; /* Include padding and border in the height */
}

/* Hover effects for button */
.fpInputButton:hover {
  background-color: #0056b3; /* Darker shade when hovered */
  box-shadow: 0 2px 4px rgba(0, 123, 255, 0.4); /* More pronounced shadow on hover */
}



.fpNavRow2 {
  position: relative; /* Add this to ensure the dropdown is positioned relative to this container */
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align button to the end of the container */
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc; /* Add a bottom border */
}

.fpSortButton {
  height: 38px;
  border: 1px solid #cccccc;
  background-color: #f8f8f8;
  color: #333333;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fpSortText {
  margin-right: 5px; /* Add some space before the arrow */
  margin-left: 5px; /* Add some space after the sort by */
}

.fpSortArrow {
  display: inline-block;
  transform: rotate(180deg); /* Flip the arrow to point upwards */
}

/* Make sure the sort menu appears below the button */
.fpSortMenu {
  position: absolute;
  right: 0; /* Align the menu to the right side of the .fpNavRow2 */
  top: 100%; /* Position the menu just below the button */
  background-color: white; /* Add a background color so it stands out over other content */
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Optional: add a shadow for better visibility */
  width: auto; /* Set the width of the menu, can be fixed or auto */
  z-index: 1000; /* Ensure the menu appears above other content */
}

/* When the sort menu is visible, you may want to change the arrow direction */
.fpSortMenu.visible .fpSortArrow {
  transform: rotate(0deg); /* Arrow points down when the menu is visible */
}

/* Styling for the sort menu items */
.fpSortMenu div {
  padding: 5px 10px;
  cursor: pointer;
}

/* Styling when sort menu is visible */
.fpSortMenu.visible {
  display: block;
}

/* Hidden by default */
.fpSortMenu {
  display: none;
}

.fpNavRow3 {
  position: relative; /* Add this to ensure the dropdown is positioned relative to this container */
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align button to the end of the container */
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc; /* Add a bottom border */
  flex-wrap: wrap; /* Allow items to wrap onto the next line */
}

.fpNavCategoryLink {
  text-decoration: none; /* Remove the underline from links */
  color: #000; /* Set the color of the links, change as needed */
  margin-right: 20px; /* Add space between the links, adjust as needed */
  margin-bottom: 20px; /* Add bottom margin to create space between rows */
  /* Add other styles like padding, font-weight, etc., according to your design */
}
.fpNavCategoryLink:last-child {
  margin-right: 0;
}

.fpTwoColGrid {
  display: flex;
  align-items: flex-start; /* Align to the top */
  justify-content: flex-start; /* Align items to the start of the container */
  width: 100%;
  padding: 10px; /* Add some padding around the elements */
  box-sizing: border-box; /* Ensure padding doesn't affect the overall width */
}

.fpLeftNav1 {
  /* display: flex; */
  /* Give the sidebar a flexible size but allow it to shrink/grow as needed */
  flex: 1; 
  padding: 10px;
  box-sizing: border-box; /* Include padding in the width calculation */
    /* Add a light gray border to the right side */
  border-right: 1px solid #ccc;
}

.fpLeftTypes {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.fpLeftTypeslist {
  padding: 0; /* Remove padding from list items */
  margin: 0; /* Remove margin to start from the very top */
}

.fpLeftTypeslist:not(:first-child) {
  padding-left: 20px; /* Add an indent to all list items except the first */
}

.fpLeftTypeslink {
  text-decoration: none; /* Remove the default text decoration */
  color: #000; /* Set the text color */
  display: block; /* Make the links fill the available horizontal space */
  padding: 5px; /* Add some padding for better clickability and appearance */
  transition: background-color 0.3s; /* Smooth transition for hover effect */

  display: inline-block; /* Ensure the element respects width */
  white-space: normal; /* Allow the text to wrap to the next line */
  /* word-wrap: break-word;  */
  /* Break long words if necessary */
  max-width: 150px; /* Set your desired maximum width */
  overflow-wrap: break-word; /* Break the word only if neces
}

.fpLeftTypeslink:hover {
  background-color: #f0f0f0; /* Change background on hover */
}


.fpResultsGrid_v1 {
  display: flex;
  flex-direction: column; /* Stack .fpStoreGrid items vertically */
  align-items: flex-start; /* Align items to the start of the container */
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.fpResultsGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Creates 5 columns */
  gap: 20px; /* Space between grid items */
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
}
.fpProductGrid_v1 {
  display: flex;
  flex-direction: row; /* Align image and info side by side */
  align-items: flex-start; /* Align items to the top */
  width: 100%;
  margin-bottom: 20px; /* Space between Product grid items */
  box-sizing: border-box;
}

.fpProductGrid {
  display: flex;
  flex-direction: column; /* Stack image and info vertically */
  align-items: center; /* Center items for a better visual appearance */
  box-sizing: border-box;
  /* adjust_v3 */
  max-width: 100%;
  cursor: pointer;
   /* Add a top border for separation */
    /* Adjust the color (#ccc is a light gray) as needed for subtlety */
   /* border-top: 1px solid #ccc; */
   background-color: #f7f7f7; /* A light gray background */
}

.fpProductImage_v1 {
  flex: 0 0 auto; /* Do not grow or shrink, use image's size */
  margin-right: 20px; /* Space between image and info */
}

.fpProductImage {
  width: 100%; /* Ensure image container fills grid item width */
  margin-bottom: 10px; /* Space between image and info */
  /* adjust_v2 */
  display: flex; /* Allows you to use justify-content and align-items */
  justify-content: center;
}

.fpProductImage img {
  max-width: 210px; /* Set a fixed width for the image */
  width:100%;
  height: auto; /* Maintain aspect ratio */
  /* adjust_v2 */
  object-fit: cover;/* Ensures the image covers the area, might crop if necessary */
  max-height: 200px; /* Adjust this value based on desired image height */
   display: block; /*Ensures the image is displayed as a block-level element */
     /* adjust_v3,for taller picture not being croped at the top of that. */
  object-position: top; /* Aligns the image to the top of the container */
}

.fpProductInfo_v1 {
  flex: 1; /* Take up the remaining space */
  display: flex;
  flex-direction: column; /* Stack info items vertically */
  padding-left: 20px; /* Add some padding for spacing from the image */
}

.fpProductInfo {
  display: flex;
  flex-direction: column; /* Stack info items vertically */
  align-items: center; /* Center-align the text for aesthetics */
  max-width: 100%; /* Ensure info section fills grid item width */
}


.fpProductInfo * { /* This will target all elements within .fpProductInfo */
  font-size: 16px; /* Increase the base font size */
  margin: 5px 0; /* Add some margin to each element */

}
.fpProductInfo .fpBottomGroup {
  margin-top: auto; /* Push to the bottom */
  display: flex;        /* Enable flexbox for the wrapper */
  flex-direction: column; /* Stack elements vertically */ 
  align-items: flex-start; /* Align items to the left */
  width: 100%;          /* Make the wrapper take full width */
}

.fpProductName  {
  /* margin: 0;
  padding: 0; */
  font-size: 16px; /* Larger text for Product name,24px before */
  font-weight: bold; /* Make the Product name bold */
  color: #333; /* Darker color for the name */
 
}

.fpProductwp{
  /* margin: 5px 0;  */
  /* Vertical spacing between info items */
  /* padding: 0; */
  font-size: 16px; /* Standard text size */
  color: #000000; /* Slightly lighter color for details */
  font-weight: lighter;
  align-self: flex-start; /* Align this child element to the start (left) */
  text-decoration: line-through;
}


.fpProductcp,
.fpstoreDisplayName,
.fpProductAddress,
.fpProductTypes,
.fpProductPhone {
  /* margin: 5px 0;  */
  /* Vertical spacing between info items */
  /* padding: 0; */
  font-size: 18px; /* Standard text size */
  color: #000000; /* Slightly lighter color for details */
  font-weight: bold;
  align-self: flex-start; /* Align this child element to the start (left) */
}

.fpProductRating {
  display: flex;
  align-items: center; /* Align rating number and text */
  font-size: 18px;
  /* margin: 5px 0;  */
  /* Space between rating and other info */
}

.fpProductRating span:first-child {
  font-weight: bold; /* Make the rating number bold */
  margin-right: 5px; /* Space between rating number and 'reviews' */
}

.fpNavUrl,
.fpProductWebsite {
  color: #333; /* Use a blue color for links */
  text-decoration: none; /* No underline */
  margin: 5px 0; /* Space between link and other info */
  font-size: 16px;/*18px before*/

    /* adjust v-3*/
    /* Ensures default wrapping behavior */
    /* white-space: normal;  */
    /* Allows unbreakable words to be broken */
    word-wrap: break-word; 
    max-width: 210px; /* Adjust as necessary */
    width:100%;
    /* Similar to word-wrap, ensures words break properly */
    overflow-wrap: break-word; 
}

.fpNavUrl:hover,
.fpProductWebsite:hover {
  /* text-decoration: underline; */
   /* Underline on hover for better visibility */
}

.fphighlight {
  background-color: #d4edda; /* Soft green background */
  color: #155724; /* Dark green text color for contrast */
  background-color: #fff3cd; /* Light yellow background */
  color: #856404; /* Dark yellow text color for contrast */
  background-color: #f8d7da; /* Soft pink background */
  color: #721c24; /* Dark pink/red text color for contrast */
  border-radius: 5px; /* Optional: to give a rounded edge */
  padding: 2px 4px; /* Optional: to add some padding */
}

/* Ensure that the layout does not break on smaller screens */
@media (max-width: 768px) {
  .fpProductGrid {
    flex-direction: column; /* Stack image and info on top of each other */
  }

  .fpProductImage {
    margin-bottom: 10px; /* Space between image and info on small screens */
  }
}
