.business-owner {
    background: linear-gradient(to right, #ff5f6d, #ffc371);
    color: white;
    padding: 40px;
    border-radius: 8px;
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
}

.business-owner h2 {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
}

.business-owner p {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.newsletter-signup {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.newsletter-signup input[type="email"] {
    flex: 1;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
    outline: none;
}

.newsletter-signup button {
    padding: 10px 20px;
    font-size: 1em;
    font-weight: bold;
    color: white;
    background-color: #0056b3;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s;
}

.newsletter-signup button:hover {
    background-color: #004080;
}

.fa-envelope {
    margin-right: 10px;
}