body{
  font-family: Arial, sans-serif;
}

.hpLogo {
  color: white; /* Logo text color */
  font-size: 24px; /* Logo text size */
  font-weight: bold; /* Logo text weight */
  /* If you want the logo to have more space on the left, adjust the margin-left */
  margin-left: 15%; /* This pushes the logo slightly to the right */
}

.hpNavigationMenu {
  list-style: none;
  display: flex;
  gap: 20px; /* Adjust the gap between menu items */
  color: white; /* Text color */
  margin: 0;
  padding: 0;
  /* If you want the navigation to have more space on the right, adjust the margin-right */
  margin-right: 16%; /* This pushes the navigation slightly to the left */
}

.hpBackgroundContainer {
  width: 100%;
  max-width: 1792px; /* Maximum width to match your image width */
  height: 1024px; /* Set the height to match the image height */
  margin-left: auto; /* Center the container */
  margin-right: auto; /* Center the container */
  background-size: cover; /* This will cover the entire area of the container, might cut off some parts if the container's aspect ratio doesn't match the image */
  background-position: center center; /* Center the image within the container */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-image: url('../public/background.webp'); 
  /* filter: blur(1px);  */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align children to the start */
}
.hpTopBanner {
  width: 100%;
  max-width: 1792px; /* Maximum width to match your image width */
  background-color: rgba(0, 0, 50, 0.5); /* Semi-transparent blue background */
  padding: 10px 0%; /* Use percentage for padding to push contents towards the center */
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hpNavigationMenu li {
  cursor: pointer;
}

.hpBottomBanner {
  width: 100%;
  max-width: 1792px; /* Maximum width to match your image width */
  background-color: rgba(0, 0, 50, 0.5); /* Same semi-transparent blue background */
  padding: 10px 0%; /* Similar padding as the top banner for consistency */
  /* No position absolute needed */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hpBottomBanner.hidden {
  visibility: hidden;
}

.hpBottomBanner.visible {
  visibility: visible;
}

.hpBottomBanner .hpCopyRight {
  color: white; /* Logo text color */
  font-size: 13px; /* Logo text size */
  font-weight: bold; /* Logo text weight */
  /* If you want the logo to have more space on the left, adjust the margin-left */
  margin-left: 10%; /* This pushes the logo slightly to the right */
}
.hpBottomBanner .hpStatement {
  color: rgb(209, 200, 200); /* Logo text color */
  font-size: 13px; /* Logo text size */
  font-weight: bold; /* Logo text weight */
  /* If you want the logo to have more space on the left, adjust the margin-left */
  margin-left: 10%; /* This pushes the logo slightly to the right */
}
.hpBottomBanner .hpPrivacyPolicy {
  color: white; /* Logo text color */
  font-size: 13px; /* Logo text size */
  font-weight: bold; /* Logo text weight */
  /* If you want the logo to have more space on the left, adjust the margin-left */
  margin-right:10%; /* This pushes the logo slightly to the right */
  cursor: pointer;
}



a{
  text-decoration: none; /* Removes underline */
  color: white;
}

a:focus, a.activeLink {

  color: orange; /* Changes the text color to orange */
}

.hpnavbutton {
  display: inline-block;
/* Narrower padding */
  padding: 6px 10px; 
  font-size: 16px;
  color: #fff;
  font-family: 'Roboto';
  font-weight: 700;
  width: 150px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  margin: 0; /* Remove any margin */
  transition: background-color 0.3s, transform 0.3s;
}

.hpnavbutton:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.hpnavbutton:active {
  background-color: #004080;
  transform: scale(0.98);
}

.highlight {
  font-size: 1.0em; /* Increase the font size */
  font-weight: bold; /* Make the text bold */
  color: #007bff; /* Optional: change the color to highlight */
}
.highlight-center {
  font-size: 1.0em; /* Increase the font size */
  font-weight: bold; /* Make the text bold */
  text-align: center;
}
.large-heading {
  font-size: 5.0em; /* Increase the font size */
  font-weight: bold; /* Optional: make the text bold */
  color: #333; /* Optional: change the color */
  color:orange;
  text-align: center;
}

#productarea {
  margin-top: 60px; /* Adjust this value to move the section lower */
   /* Center the button */
  /* text-align: center; */
}

#placearea {
  margin-top: 40px; /* Adjust this value to move the section lower */
   /* Center the button */
  /* text-align: center; */
}


.business-owner {
  background: linear-gradient(to right, #ff5f6d, #ffc371); /* Gradient background */
  color: white;
  padding: 40px;
  border-radius: 8px;
  text-align: left;
  max-width: 800px;
  margin: 0 auto; /* Center the section */
}

.business-owner h2 {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
}

.business-owner p {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.newsletter-signup {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.newsletter-signup input[type="email"] {
  flex: 1;
  padding: 10px;
  font-size: 1em;
  border: none;
  border: 1px solid #ccc; /* Add border */
  border-radius: 4px 0 0 4px;
  outline: none;
}

.newsletter-signup button {
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  color: white;
  background-color: #0056b3; /* Button color */
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.newsletter-signup button:hover {
  background-color: #004080; /* Darker button color on hover */
}

/* Styles for screens wider than 600px */
@media only screen and (max-width: 1500px) {
 
}
