

.pdpContainer {
  width: 70%; /* Set the width to 70% of the parent container */
  max-width: 1200px; /* Adjust based on your design, keeps it responsive */
  margin: 0 auto; /* This centers the container */
  padding: 60px; /* Adds some padding inside the container */
  background-color: #fff; /* Example background color */
  /* Add other styles such as border, box-shadow as per your design */
  display: flex;
  /* Using flexbox to align items */
  justify-content: space-between;
  /* Space out the logo, search bar, and other items */
  align-items: center;
  /* Center items vertically */
  /* position: relative; */ 
  /* Default positioning */
  z-index: 10;
  /* Ensure it's above other content */
  flex-direction: column;
  /* box-sizing: border-box; */
}

.auContainer > :first-child {
  margin-top: 10%; /* Adjust the value as needed */

}
.auContainer > :last-child {
  margin-bottom: 10%; /* Adjust the value as needed */
}

.pdpNavRow1 {
  display: flex;
  align-items: center;
  justify-content: start; /* Align items to the start of the container */
  width: 100%;
  padding: 10px; /* Add some padding around the elements */
  box-sizing: border-box; /* Ensure padding doesn't affect the overall width */
}

.pdpCategory,
.pdpType {
  margin-right: 10px; /* Space between elements */
  width: 18%; /* Set a percent  width for select elements(150px before) */
  height: 38px; /* Slightly taller for a balanced look */
  border: 1px solid #cccccc; /* Subtle border for definition */
  border-radius: 4px; /* Rounded corners for a modern look */
  box-sizing: border-box; /* Include padding and border in the height */
  background-color: #ffffff; /* White background */
  color: #333333; /* Slightly darker text for better readability */
  font-size: 14px; /* Set a standard font size */
}

.pdpInputContainer {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align the input and button to the start of the container */
}

.pdpInputWords {
  border: 1px solid #cccccc;
  border-right: none; /* Remove the right border where the button will be */
  width: 100%; /* percent  width for the input(before 600px) */
  height: 38px; /* Match the height of the selects */
  margin-right: -1px; /* Align the button with the input */
  border-radius: 4px 0 0 4px; /* Rounded corners on the left side only */
  padding-left: 8px; /* Some padding inside the input box */
  box-sizing: border-box; /* Include padding and border in the height */
  font-size: 14px; /* Match font size with select elements */
}

.pdpInputButton {
  border: 1px solid #cccccc;
  background-color: #007bff; /* A pleasant shade of blue */
  color: #ffffff; /* White text for the button */
  height: 40px; /* Height to match the input field */
  padding: 0 15px; /* Padding on the sides of the button text */
  border-radius: 0 4px 4px 0; /* Rounded corners on the right side */
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  box-shadow: 0 2px 4px rgba(0, 123, 255, 0.2); /* Slight shadow for depth */
  font-size: 14px; /* Match font size with other elements */
  box-sizing: border-box; /* Include padding and border in the height */
}

/* Hover effects for button */
.pdpInputButton:hover {
  background-color: #0056b3; /* Darker shade when hovered */
  box-shadow: 0 2px 4px rgba(0, 123, 255, 0.4); /* More pronounced shadow on hover */
}



.pdpNavRow2 {
  position: relative; /* Add this to ensure the dropdown is positioned relative to this container */
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align button to the end of the container */
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc; /* Add a bottom border */
}





.pdpNavRow3 {
  position: relative; /* Add this to ensure the dropdown is positioned relative to this container */
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align button to the end of the container */
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc; /* Add a bottom border */
  flex-wrap: wrap; /* Allow items to wrap onto the next line */
}
.pdpNavCategoryLink {
  text-decoration: none; /* Remove the underline from links */
  color: #000; /* Set the color of the links, change as needed */
  margin-right: 20px; /* Add space between the links, adjust as needed */
  margin-bottom: 20px; /* Add bottom margin to create space between rows */
  /* Add other styles like padding, font-weight, etc., according to your design */
}
.pdpNavCategoryLink:last-child {
  margin-right: 0;
}

.pdpTwoColGrid {
  display: flex;
  align-items: flex-start; /* Align to the top */
  justify-content: flex-start; /* Align items to the start of the container */
  width: 100%;
  padding: 10px; /* Add some padding around the elements */
  box-sizing: border-box; /* Ensure padding doesn't affect the overall width */
}

.pdpLeftNav1 {
  /* display: flex; */
  /* Give the sidebar a flexible size but allow it to shrink/grow as needed */
  flex: 1; 
  padding: 10px;
  box-sizing: border-box; /* Include padding in the width calculation */
    /* Add a light gray border to the right side */
  border-right: 1px solid #ccc;
}

.pdpLeftTypes {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.pdpLeftTypeslist {
  padding: 0; /* Remove padding from list items */
  margin: 0; /* Remove margin to start from the very top */
}

.pdpLeftTypeslist:not(:first-child) {
  padding-left: 20px; /* Add an indent to all list items except the first */
}

.pdpLeftTypeslink {
  text-decoration: none; /* Remove the default text decoration */
  color: #000; /* Set the text color */
  display: block; /* Make the links fill the available horizontal space */
  padding: 5px; /* Add some padding for better clickability and appearance */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.pdpLeftTypeslink:hover {
  background-color: #f0f0f0; /* Change background on hover */
}

.pdpResultsGrid {
  display: flex;
  flex-direction: column; /* Stack .pdpStoreGrid items vertically */
  align-items: flex-start; /* Align items to the start of the container */
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.pdpStoreGrid {
  display: flex;
  flex-direction: column; /* Align image and info side by side */
  align-items: flex-start; /* Align items to the top */
  width: 100%;
  margin-bottom: 20px; /* Space between store grid items */
  box-sizing: border-box;
}

.pdpStoreImage {
  flex: 0 0 auto; /* Do not grow or shrink, use image's size */
  margin-right: 20px; /* Space between image and info */
}

.pdpStoreImage img {
  width: 300px; /* Set a fixed width for the image */
  height: auto; /* Maintain aspect ratio */
}

.pdpStoreInfo {
  flex: 1; /* Take up the remaining space */
  display: flex;
  flex-direction: column; /* Stack info items vertically */
  padding-left: 20px; /* Add some padding for spacing from the image */
}

.pdpStoreInfo * { /* This will target all elements within .pdpStoreInfo */
  font-size: 16px; /* Increase the base font size */
  margin: 5px 0; /* Add some margin to each element */
}

.pdpStoreName  {
  /* margin: 0;
  padding: 0; */
  font-size: 24px; /* Larger text for store name */
  font-weight: bold; /* Make the store name bold */
  color: #333; /* Darker color for the name */
}

.pdppriceFeature,
.pdpPrimaryTypeDisplayName,
.pdpStoreAddress,
.pdpStoreTypes,
.pdpStorePhone {
  /* margin: 5px 0;  */
  /* Vertical spacing between info items */
  /* padding: 0; */
  font-size: 18px; /* Standard text size */
  color: #555; /* Slightly lighter color for details */
  display: flex;
  align-items: center;
}

.pdpStoreRating {
  display: flex;
  align-items: center; /* Align rating number and text */
  font-size: 18px;
  /* margin: 5px 0;  */
  /* Space between rating and other info */
}

.pdpStoreRating span {
  font-weight: normal;
  margin-left: 5px;
}

.pdpStoreRating span:first-child {
  font-weight: bold; /* Make the rating number bold */
  margin-right: 5px; /* Space between rating number and 'reviews' */
}
.fas {
  margin-right: 10px; /* Space between icon and text */
}

.pdpStoreRating .fas {
  color: gold; /* Change color to gold for stars */
}

.pdpMapUrl,
.pdpStoreWebsite {
  color: #007bff; /* Use a blue color for links */
  text-decoration: none; /* No underline */
  margin: 5px 0; /* Space between link and other info */
  font-size: 18px;
}

.pdpMapUrl:hover,
.pdpStoreWebsite:hover {
  text-decoration: underline; /* Underline on hover for better visibility */
}

.pdpstoredetails {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping of items */
  justify-content: flex-start; /* Align items to the start of the main axis */
  gap: 10px; /* Add space between the items */
  background-color: #f7f7f7; /* Light grey background for the section */
}

.pdpstoredetails .detailRow {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.pdprdetailitem {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically within each div */
  padding: 10px;
  border: 1px solid #ccc; /* Subtle border for visibility */
  background-color: #f9f9f9; /* Light background to distinguish items */
  width: 200px; /* Fixed width for each item */
  text-align: center; /* Center text for readability */
  margin-bottom: 10px; /* Space between rows */
}



.pdprdetailitem a {
  /* color: #1a0dab; 
  text-decoration: none;  */
  display: inline-block;
  padding: 12px 24px;
  margin: 10px 5px;
  background-color: #f9f9f9; /* Light grey background */
  color: #333; /* Dark grey text */
  text-decoration: none; /* Removes underline */
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transitions */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Subtle shadow */
  /*more effect */
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Modern font */
  padding: 12px 24px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff); /* Subtle gradient */
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Soft shadow for depth */
  border-radius: 5px; /* Rounded corners */

}

.pdprdetailitem i {
  font-size: 1.25rem; /* Adjust icon size as needed */
}
/* .pdprdetailitem a:hover {
  text-decoration: underline;
} */

.pdprdetailitem a:hover,.pdprdetailitem a:focus {
  /* background-color: #e1e1e1; */
   /* Lighter grey on hover */
  /* box-shadow: 0 4px 8px rgba(0,0,0,0.2);  */
  /* Enhanced shadow on hover */
  /* text-decoration: none; */
   /* Ensures no underline on hover */

  background-color: #d3d3d3; /* Darker grey on hover */
  box-shadow: 0 6px 12px rgba(0,0,0,0.2); /* Enhanced shadow on hover */
  transform: scale(1.05); /* Slight enlargement on hover */
  text-decoration: none; /* Ensures no underline on hover */
}
.pdprdetailitem img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  transition: transform 0.3s ease-in-out;
}
.pdprdetailitem:hover img {
  transform: scale(1.05); /* Slight zoom on hover */
}
/* Ensure that the layout does not break on smaller screens */
@media (max-width: 768px) {
  .pdpStoreGrid {
    flex-direction: column; /* Stack image and info on top of each other */
  }

  .pdpStoreImage {
    margin-bottom: 10px; /* Space between image and info on small screens */
  }
}
