/* GeocodeModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 100%;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  h2 {
    margin: 0;
    margin-bottom: 20px;
    font-size: 1.5rem;
  }
  
  .input-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .input-container input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
    font-size: 1rem;
  }
  
  .clear-button {
    padding: 10px;
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 0 5px 5px 0;
    background: #f5f5f5;
    cursor: pointer;
  }
  
  .results-list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 300px;
    overflow-y: auto;
  }
  
  .results-list li {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }
  
  .result-icon {
    font-size: 1.5rem;
    margin-right: 10px;
  }
  
  .result-text {
    display: flex;
    flex-direction: column;
  }
  
  .result-address {
    font-weight: bold;
  }
  
  .result-details {
    color: #555;
  }
  