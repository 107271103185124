/* Pagination.css */
.pagination ul {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
  }
  
  .pagination .page-item {
    margin: 0 5px;
  }
  
  .pagination .page-item a {
    display: block;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 50%;
    cursor: pointer;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    color: #333;
    outline: none;
  }
  
  .pagination .page-item a:hover:not([aria-disabled='true']) {
    border-color: #999;
  }
  
  .pagination .page-item a[aria-disabled='true'] {
    pointer-events: none;
    opacity: 0.5;
  }
  
  .pagination .page-item.active a {
    background-color: #333;
    color: #fff;
    border-color: #333;
  }
  
  .pagination .page-item.disabled a {
    visibility: hidden;
  }
  